

























































































































import Vue from "vue";
import axios from "axios";
import { Document } from "@/types/document";

export default Vue.extend({
  name: "DocumentsComponent",
  data: () => ({
    results: [],
    documentTypes: ["Alle"],
    resultTypes: ["Alle", "Alleen met fouten"],
    selectedDocumentType: "Alle",
    selectedResultType: "Alle",
  }),
  computed: {
    filteredResults: function (): Document[] {
      return this.results.filter(
        (result: Document) =>
          (result.object_type == this.selectedDocumentType ||
            this.selectedDocumentType === "Alle") &&
          (result.findings.some((x) => x.Finding.result == false) ||
            this.selectedResultType == "Alle")
      );
    },
  },
  methods: {
    getresults() {
      axios
        .get(
          `${this.$store.state.APIurl}/finding/documents?skip=0&limit=1000`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.user.token}`,
            },
          }
        )
        .then((response) => {
          this.$data.results = response.data;
          const presentTypes: string[] = [
            ...new Set(
              this.$data.results.map((result: Document) => result.object_type)
            ),
          ] as string[];
          this.$data.documentTypes = ["Alle"].concat(presentTypes);
        })
        .catch((error) => {
          this.$data.results = [];
        });
    },
  },
  mounted() {
    this.getresults();
  },
});
